import {
  Button,
  Form, Input, Modal, Radio, Select, Space,
} from 'antd'
import React, { useEffect, useState } from 'react'
import './site.scss'
import { useDispatch, useSelector } from 'react-redux';
import { getCountryDataAction, getStateDataAction, getStateDataResetAction } from '../GeneralInformation/logic';
import { getCookie } from '../../../utils';
import { ListUsersAction } from '../ShareProjectModal/logic';

export default function SiteOtherPersonnel({ data }) {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { Option } = Select;
  const getCountryData = useSelector((store) => store.getCountryData);

  const [editClick, setEditclicked] = useState(false)
  const [showDeleteModal, setDeleteModal] = useState(false)
  const [countryList, setCountryList] = useState([])
  const getStateData = useSelector((store) => store.getStateData);

  const [userList, setUserList] = useState([])
  const useresListResponse = useSelector((store) => store.usersList);

  useEffect(() => {
    if (useresListResponse.flag) {
      setUserList(useresListResponse.data.data.users)
    }
  }, [JSON.stringify(useresListResponse)])

  useEffect(() => {
    dispatch(getCountryDataAction())
  }, [])

  useEffect(() => {
    dispatch(ListUsersAction())
  }, [])

  useEffect(() => {
    if (getCountryData.flag) {
      setCountryList(getCountryData?.data?.distinct_countries)
    }
  }, [JSON.stringify(getCountryData)])

  const [checkboxState, setCheckboxState] = useState({
    role: false,
    role_at_site: false,
    full_name: false,
    // first_name: false,
    // last_name: false,
  });

  const [editingData, setEditingData] = useState(null);
  const [fullName, setFullName] = useState('')
  const [role, setRole] = useState('')

  const handleSave = () => {
    const values = form.getFieldsValue();
    const data1 = userList?.filter((itm) => itm.id === values.full_name)[0]

    const newData = {
      role: { old: '', new: values.role, flag: true },
      role_at_site: { old: '', new: values.role_at_site, flag: true },

      // first_name: { old: '', new: values.first_name, flag: true },
      // last_name: { old: '', new: values.last_name, flag: true },
      full_name: { old: '', new: data1 === undefined ? values.full_name : data1.name, flag: true },
      mobile_number: { old: '', new: values.mobile_number, flag: true },
      fax: { old: '', new: values.fax, flag: true },
      email: { old: '', new: values.email, flag: true },
      same_location: { old: '', new: values.same_location === true ? true : values.same_location === false ? false : undefined, flag: true },
      // same_location: { old: '', new: values.same_location === undefined ? true : values.same_location, flag: true },
      address: {
        address_line1: { old: '', new: values.address_line1, flag: true },
        address_line2: { old: '', new: values.address_line2, flag: true },
        country: { old: '', new: values.country, flag: true },
        state: { old: '', new: values.state, flag: true },
        zip: { old: '', new: values.zip, flag: true },
        city: { old: '', new: values.city, flag: true },
      },
      id: { old: '', new: data1 === undefined ? '' : data1.id, flag: true },
    };
    if (editingData == null) {
      data.setOtherData((old) => [...old, newData]);
    } else if (data.otherData.length) {
      data.setOtherData((old) => old.map((item, index) => (index === editingData ? newData : item)));
    } else {
      data.setOtherData([newData])
    }

    form.resetFields();
    setEditingData(null);
    setEditclicked(false)
    setAddress('hide_address')
    setRole('')
    setCheckboxState({
      role: false, role_at_site: false, full_name: false,
    });
  };

  const handleEdit = (index) => {
    setEditclicked(true)
    if (data.otherData[index].same_location.old === false || data.otherData[index].same_location.new === false) {
      setAddress('show_address')
    } else {
      setAddress('hide_address')
    }
    if (data.otherData[index].role.new === 'Parexel Contact' || data.otherData[index].role.old === 'Parexel Contact') {
      setRole('Parexel Contact')
    } else if (data.otherData[index].role.new !== 'Parexel Contact' || data.otherData[index].role.old !== 'Parexel Contact') {
      setRole(data.otherData[index].role.new || data.otherData[index].role.old)
    } else {
      setRole('')
    }
    if (data.otherData[index].full_name.new !== '') {
      setFullName(data.otherData[index].full_name.new)
    } else if (data.otherData[index].full_name.old !== '') {
      setFullName(data.otherData[index].full_name.old)
    } else {
      setFullName('')
    }
    form.setFieldsValue({
      role: data.otherData[index].role.old || data.otherData[index].role.new,
      role_at_site: data.otherData[index].role_at_site.old || data.otherData[index].role_at_site.new,
      full_name: data.otherData[index].full_name.old || data.otherData[index].full_name.new,
      id: data.otherData[index].id.old || data.otherData[index].id.new,
      // first_name: data.otherData[index].first_name.old || data.otherData[index].first_name.new,
      // last_name: data.otherData[index].last_name.old || data.otherData[index].last_name.new,
      mobile_number: data.otherData[index].mobile_number.old || data.otherData[index].mobile_number.new,
      fax: data.otherData[index].fax.old || data.otherData[index].fax.new,
      email: data.otherData[index].email.old || data.otherData[index].email.new,
      same_location: data.otherData[index].same_location.old === ''
        ? data.otherData[index].same_location.new : data.otherData[index].same_location.old,

      address_line1: data.otherData[index].address.address_line1.old || data.otherData[index].address.address_line1.new,
      address_line2: data.otherData[index].address.address_line2.old || data.otherData[index].address.address_line2.new,
      country: data.otherData[index].address.country.old || data.otherData[index].address.country.new,
      state: data.otherData[index].address.state.old || data.otherData[index].address.state.new,
      zip: data.otherData[index].address.zip.old || data.otherData[index].address.zip.new,
      city: data.otherData[index].address.city.old || data.otherData[index].address.city.new,

    });
    setEditingData(index);
  };

  const getValue = (d, keyName) => {
    if (keyName === 'same_location') {
      if (d[keyName]?.new === true) {
        return d[keyName]?.new
      }
      if (d[keyName]?.new === false) {
        return d[keyName]?.new
      }
      return d[keyName]?.old
    }

    if (d[keyName]?.flag && keyName !== 'full_name') {
      return d[keyName]?.new
    }
    if (keyName === 'address_line1' || keyName === 'address_line2' || keyName === 'city' || keyName === 'state' || keyName === 'country' || keyName === 'zip') {
      if (d.address[keyName].new) {
        return d.address[keyName].new
      }
      return d.address[keyName].old
    }
    if (keyName === 'full_name') {
      if (d.full_name.new) {
        return d.full_name.new
      }
      return d.full_name.old
    }
    return d[keyName]?.old
  }

  const onDeleteClick = () => {
    setDeleteModal(true)
  }

  const deleteCompliance = () => {
    data.otherData.splice(editingData, 1)
    setDeleteModal(false)
    setEditclicked(false)
    setEditingData(null)
    setFullName('')
    form.setFieldsValue({
      role: '',
      role_at_site: '',
      // first_name: '',
      // last_name: '',
      full_name: '',
      email: '',
      mobile_number: '',
      fax: '',
      same_location: '',
      address: {},
    });
  }

  const checkDisable = () => {
    const values = form.getFieldsValue();
    return (values.role === undefined || fullName === '')
  }

  const [showAddress, setAddress] = useState('')
  const handleCheckboxChange = (e, keyName) => {
    if (keyName === 'role') {
      setRole(e)
    }
    if (keyName === 'full_name' && role !== 'Parexel Contact') {
      setFullName(e.target.value)
    } else if (keyName === 'full_name' && role === 'Parexel Contact') {
      setFullName(e.name)
    }
    if (keyName === 'country') {
      dispatch(getStateDataAction({ country: e }))
      form.setFieldsValue({ state: '' })
      dispatch(getStateDataResetAction())
    }
    if (keyName === 'role' && e !== 'Parexel Contact') {
      form.setFieldsValue({ full_name: '' })
      form.setFieldsValue({ email: '' })
      setFullName('')
    }
    if (keyName === 'role' && e === 'Parexel Contact') {
      form.setFieldsValue({ full_name: '' })
      form.setFieldsValue({ email: '' })
      setFullName('')
    }

    if (keyName === 'full_name' && role === 'Parexel Contact') {
      form.setFieldsValue({ email: e.email })
    }
    if (keyName === 'same_location') {
      if (e.target.value) {
        setAddress('hide_address')
      } else {
        setAddress('show_address')
      }
      setCheckboxState({ ...checkboxState, [keyName]: true });
    }
    setCheckboxState({ ...checkboxState, [keyName]: true });
  };
  const userId = getCookie('userName')

  return (
    <div className={(userId === data.checkUserId && data.checkStatus === 'Send_for_clarification') ? 'compliance-page-wrapper' : 'compliance-page-wrapper disable-pi'}>
      <div className="compliance-page">
        <div className="main-head-wrap">Other Key Personnel</div>
        <Form
          layout="vertical"
          name="piform"
          form={form}
          className={data.centerStatus !== 'Send_for_clarification' && (getCookie('permissions').includes('super_admin') || getCookie('permissions').includes('lcor_access') || getCookie('permissions').includes('sam_access')) && (data.flag === 'admin' || data.flag === 'edit-admin') ? 'disable-pi-form' : null}
        >
          <div className="head-wrapper">
            <div className="inner-card">
              <div className="combine-content-from">
                <div className="role-card">
                  <Form.Item
                    label="Contact Type"
                    className="wrapper-card"
                    name="role"
                    rules={[{ required: true, message: 'This is required field' }]}
                  >
                    <Select
                      className="input-wrapper"
                      placeholder="Select Contact Type"
                      // value={getValue('role')}
                      onChange={(e) => handleCheckboxChange(e, 'role')}
                    >
                      {
                        data.parentFlag ? (
                          <>
                            <Option value="Parexel Contact">Parexel Contact</Option>
                            <Option value="Alliance Contact">Alliance Contact</Option>
                          </>
                        ) : (
                          <>
                            <Option value="Admin">Admin</Option>
                            <Option value="Parexel Contact">Parexel Contact</Option>
                            <Option value="Alliance Contact">Alliance Contact</Option>
                            <Option value="Blinded Rater">Blinded Rater</Option>
                            <Option value="Blinded Reader">Blinded Reader</Option>
                            <Option value="Contract Contact">Contract Contact</Option>
                            <Option value="Contract and Grant Contact">Contract and Grant Contact</Option>
                            <Option value="Data Entry">Data Entry</Option>
                            <Option value="Dermatologist">Dermatologist</Option>
                            <Option value="Dietician">Dietician</Option>
                            <Option value="Drug Recipient">Drug Recipient</Option>
                            <Option value="EC Chairperson">EC Chairperson</Option>
                            <Option value="Feasibility Contact">Feasibility Contact</Option>
                            <Option value="Grant Contact">Grant Contact</Option>
                            <Option value="Head Of Department">Head Of Department</Option>
                            <Option value="IRB Contact">IRB Contact</Option>
                            <Option value="Lab Report Receiver">Lab Report Receiver</Option>
                            <Option value="Lab Sample Pickup">Lab Sample Pickup</Option>
                            <Option value="Laboratory Technician">Laboratory Technician</Option>
                            <Option value="Material Recipient">Material Recipient</Option>
                            <Option value="Nurse">Nurse</Option>
                            <Option value="Ophthalmologist">Ophthalmologist</Option>
                            <Option value="Pharmacist">Pharmacist</Option>
                            <Option value="Radiologist">Radiologist</Option>
                            <Option value="Regulatory Contact">Regulatory Contact</Option>
                            <Option value="Site Safety Coordinator">Site Safety Coordinator</Option>
                            <Option value="Sponsor Project Manager">Sponsor Project Manager</Option>
                            <Option value="Study Coordinator">Study Coordinator</Option>
                            <Option value="Sub Investigator">Sub Investigator</Option>
                            <Option value="Unblinded Investigator">Unblinded Investigator</Option>
                            <Option value="Unblinded Study Coordinator">Unblinded Study Coordinator</Option>
                            <Option value="Cell and Gene Therapy Contact">Cell and Gene Therapy Contact</Option>
                          </>
                        )
                      }
                    </Select>
                  </Form.Item>
                </div>
                <div className="lname-card">
                  <Form.Item
                    label="Role at site"
                    className="lname-wrapper"
                    name="role_at_site"
                  >
                    <Input
                      className="input-wrapper"
                      placeholder="Enter role at site"
                      // value={getValue('role_at_site')}
                      onChange={(e) => handleCheckboxChange(e, 'role_at_site')}
                    />
                  </Form.Item>
                </div>
                {
                   role === 'Parexel Contact' ? (
                     <div className="role-card" style={{ paddingRight: '10px' }}>
                       <Form.Item
                         label="Name"
                         name="full_name"
                         className="wrapper-card "
                         rules={[{ required: true, message: 'This is required field' }]}
                       >
                         <Select
                           showSearch
                           placeholder="Select Name"
                           className="input-wrapper"
                           optionFilterProp="children"
                           filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                           filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
                           onChange={(e) => handleCheckboxChange(userList?.filter((itm) => itm.id === e)[0], 'full_name')}
                         >
                           {userList && userList?.map((itm) => {
                             return <Option value={itm.id}>{itm.name}</Option>
                           })}
                         </Select>

                       </Form.Item>
                     </div>
                   )

                     : (
                       <div className="fname-card" style={{ paddingRight: '6px' }}>
                         <Form.Item
                           label="Name"
                           className="fname-wrapper"
                           name="full_name"
                           rules={[{ required: true, message: 'This is required field' }]}
                         >
                           <Input
                             className="input-wrapper"
                             placeholder="Enter name"
                        // value={getValue('first_name')}
                             onChange={(e) => handleCheckboxChange(e, 'full_name')}
                           />
                         </Form.Item>
                       </div>

                     )
                }
                {/* <div className="fname-card" style={{ paddingRight: '6px' }}>
                  <Form.Item
                    label="First Name"
                    className="fname-wrapper"
                    name="first_name"
                  >
                    <Input
                      className="input-wrapper"
                      placeholder="Enter first name"
                      // value={getValue('first_name')}
                      onChange={(e) => handleCheckboxChange(e, 'first_name')}
                    />
                  </Form.Item>
                </div>
                <div className="lname-card">
                  <Form.Item
                    label="Last Name"
                    className="lname-wrapper"
                    name="last_name"
                  >
                    <Input
                      className="input-wrapper"
                      placeholder="Enter last name"
                      // value={getValue('last_name')}
                      onChange={(e) => handleCheckboxChange(e, 'last_name')}
                    />
                  </Form.Item>
                </div> */}
                <div className="phone-card">
                  <Form.Item
                    label="Phone Number"
                    className="phone-wrapper"
                    rules={[{
                      pattern: /^[0-9\\,\-\\+ ]+$/, message: 'Enter a valid phone no.',
                    }]}
                    name="mobile_number"
                  >
                    <Input
                      className="input-wrapper"
                      placeholder="Enter phone number"
                      // value={getValue('mobile_number')}
                      onChange={(e) => handleCheckboxChange(e, 'mobile_number')}
                    />
                  </Form.Item>
                </div>
                <div className="fix-card" style={{ paddingRight: '7px' }}>
                  <Form.Item
                    label="Fax Number"
                    className="fax-wrapper"
                    name="fax"
                  >
                    <Input
                      className="input-wrapper"
                      placeholder="Enter fax number"
                      // value={getValue('fax')}
                      onChange={(e) => handleCheckboxChange(e, 'fax')}
                    />
                  </Form.Item>
                </div>
                <div className="email-card" style={{ paddingRight: '10px' }}>
                  <Form.Item
                    label="Email"
                    className="email-wrapper "
                    name="email"
                    rules={[{ type: 'email', message: 'Enter a valid email' }]}
                  >
                    <Input
                      className="input-wrapper"
                      placeholder="Enter email"
                      // value={getValue('email')}
                      onChange={(e) => handleCheckboxChange(e, 'email')}
                    />
                  </Form.Item>
                </div>
                <div className="location-card">
                  <Form.Item
                    label="Same Location?"
                    name="same_location"
                    className="location-wrapper"
                  >
                    <Radio.Group onChange={(e) => handleCheckboxChange(e, 'same_location')}>
                      <Space direction="vertical">
                        <Radio value>Yes</Radio>
                        <Radio value={false}>No</Radio>
                      </Space>
                    </Radio.Group>
                  </Form.Item>
                </div>
                {showAddress === 'show_address' && (
                <div className="same-loaction-sec">

                  <div className="fname-card">
                    <Form.Item
                      label="Country"
                      className="fname-wrapper"
                      rules={[{ type: 'string', min: 3, message: 'Minimum 3 characters' }]}
                      name="country"
                    >
                      <Select
                        showSearch
                        placeholder="Select country"
                        style={{
                          width: 120,
                        }}
                        onChange={(e) => handleCheckboxChange(e, 'country')}
                      >
                        {countryList && countryList?.map((itm) => {
                          return <Option value={itm}>{itm}</Option>
                        })}
                      </Select>
                    </Form.Item>
                  </div>

                  <div className="fname-card">
                    <Form.Item
                      label="State"
                      className="fname-wrapper"
                      rules={[{ type: 'string', min: 3, message: 'Minimum 3 characters' }]}
                      name="state"
                    >
                      {getStateData?.data?.states?.length !== 1 ? (
                        <Select
                          showSearch
                          placeholder="Select state"
                          style={{
                            width: 120,
                          }}
                        >
                          {getStateData.flag && getStateData?.data?.states?.map((itm) => {
                            return <Option key={itm} value={itm}>{itm}</Option>
                          })}
                        </Select>
    )
                        : (
                          <Input
                            className="input-wrapper"
                            placeholder="Enter state"
                          />
      )}
                    </Form.Item>
                  </div>

                  <div className="fname-card">
                    <Form.Item
                      label="City"
                      className="fname-wrapper"
                      name="city"
                      rules={[{ type: 'string', min: 3, message: 'Minimum 3 characters' }]}
                    >
                      <Input
                        className="input-wrapper"
                        placeholder="Enter City"
                      />
                    </Form.Item>
                  </div>

                  <div className="fname-card">
                    <Form.Item
                      label="Address Line 1"
                      className="fname-wrapper"
                      rules={[{ type: 'string', min: 3, message: 'Minimum 3 characters' }]}
                      name="address_line1"
                    >
                      <Input
                        className="input-wrapper"
                        placeholder="Enter address line 1"
                      />
                    </Form.Item>
                  </div>

                  <div className="fname-card">
                    <Form.Item
                      label="Address Line 2"
                      className="fname-wrapper"
                      rules={[{ type: 'string', min: 3, message: 'Minimum 3 characters' }]}
                      name="address_line2"
                    >
                      <Input
                        className="input-wrapper"
                        placeholder="Enter address line 2"
                        // value={getValue('address_line2')}
                        // onChange={(e) => onOtherDataStateChange(e, 'address_line2')}
                      />
                    </Form.Item>
                  </div>

                  <div className="fname-card">
                    <Form.Item
                      label="Postal Code"
                      className="fname-wrapper"
                      rules={[{ type: 'string', min: 3, message: 'Minimum 3 characters' }]}
                      name="zip"
                    >
                      <Input
                        className="input-wrapper"
                        placeholder="Enter postal"
                      />
                    </Form.Item>
                  </div>
                </div>
                )}
              </div>

            </div>
          </div>
        </Form>
        <div className="btn-wrap">
          <div className="save-btn-sec">
            <Button
              type="primary"
              disabled={checkDisable()}
              onClick={handleSave}
            > Save and Add
            </Button>
          </div>
          <div className="delete-btn-sec">
            {editClick && <Button disabled={(userId === data.checkUserId && data.checkStatus !== 'Send_for_clarification')} type="primary" onClick={onDeleteClick}>Delete</Button>}
          </div>
        </div>
        {showDeleteModal && (
          <Modal
            title="Delete Other Personnel Details"
            open={showDeleteModal}
            onCancel={() => { setDeleteModal(false) }}
            destroyOnClose
            footer={null}
            className="showDeleteModal-modal"
          >
            Are you Sure you want to delete this?
            <div className="modal-btn-wrap">
              <Button type="primary" onClick={deleteCompliance}> Yes </Button>
              <Button type="primary" onClick={() => setDeleteModal(false)}> Cancel </Button>
            </div>
          </Modal>
        )}
      </div>
      {data.otherData.length > 0 ? (data.otherData.map((d, i) => (
        <div key={i} className="compliance-head">
          <div className="compliance-head-value">
            <div className="compliance-head-value-title"> Contact Type </div>
            <div className="compliance-head-value-desc"> {getValue(d, 'role')} </div>
          </div>
          <div className="compliance-head-value">
            <div className="compliance-head-value-title"> Role at site  </div>
            <div className="compliance-head-value-desc"> {getValue(d, 'role_at_site')} </div>
          </div>
          <div className="compliance-head-value">
            <div className="compliance-head-value-title"> Name  </div>
            <div className="compliance-head-value-desc"> {getValue(d, 'full_name')} </div>
          </div>
          {/* <div className="compliance-head-value">
            <div className="compliance-head-value-title"> Last Name </div>
            <div className="compliance-head-value-desc"> {getValue(d, 'last_name')} </div>
          </div> */}
          <div className="compliance-head-value">
            <div className="compliance-head-value-title">Phone Number</div>
            <div className="compliance-head-value-desc"> {getValue(d, 'mobile_number')} </div>
          </div>
          <div className="compliance-head-value">
            <div className="compliance-head-value-title"> Fax </div>
            <div className="compliance-head-value-desc"> {getValue(d, 'fax')} </div>
          </div>
          <div className="compliance-head-value">
            <div className="compliance-head-value-title"> Email </div>
            <div className="compliance-head-value-desc"> {getValue(d, 'email')} </div>
          </div>
          <div className="compliance-head-value">
            <div className="compliance-head-value-title"> Same Location </div>
            <div className="compliance-head-value-desc"> {getValue(d, 'same_location') === true ? 'Yes' : getValue(d, 'same_location') === false ? 'No' : undefined} </div>
          </div>
          <>
            <div className="compliance-head-value">
              <div className="compliance-head-value-title"> Address Line 1 </div>
              <div className="compliance-head-value-desc"> {getValue(d, 'address_line1')} </div>
            </div>
            <div className="compliance-head-value">
              <div className="compliance-head-value-title"> Address Line 2 </div>
              <div className="compliance-head-value-desc"> {getValue(d, 'address_line2')} </div>
            </div>
            <div className="compliance-head-value">
              <div className="compliance-head-value-title"> City </div>
              <div className="compliance-head-value-desc"> {getValue(d, 'city')} </div>
            </div>
            <div className="compliance-head-value">
              <div className="compliance-head-value-title"> State </div>
              <div className="compliance-head-value-desc"> {getValue(d, 'state')} </div>
            </div>
            <div className="compliance-head-value">
              <div className="compliance-head-value-title"> Country </div>
              <div className="compliance-head-value-desc"> {getValue(d, 'country')} </div>
            </div>
            <div className="compliance-head-value">
              <div className="compliance-head-value-title"> Postal Code </div>
              <div className="compliance-head-value-desc"> {getValue(d, 'zip')} </div>
            </div>
          </>
          <div className="edit-btn-sec"><Button className="edit-btn" disabled={(userId === data.checkUserId && data.checkStatus !== 'Send_for_clarification')} onClick={() => handleEdit(i)} /></div>
        </div>
      ))) : null}
    </div>
  )
}
