import React, { useState, useEffect } from 'react';
import {
  Popover,
  Modal,
  Select,
  Collapse,
  Switch,
  Button,
  Tooltip,
} from 'antd';
import { useLocation, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { stringify, parse } from 'query-string';
import { CaretRightOutlined } from '@ant-design/icons';
import Axios from 'axios';
import {
  getCountsDataAction,
  getDatasetListDataAction,
  getSearchFieldsFilterAction,
  getMapCoordinatesAction,
  getMapCoordinatesResetAction,
} from './logic';
import Loader from '../../../common/components/Loader';
import WorldMap from '../../../common/components/Map';
import PIMap from '../../../common/components/PIMap';
import DocumentList from '../DocumentList';
import {
  getCookie,
  hasPermissionForAction,
  isViewOnlyAccess,
  setAnalytics,
} from '../../../utils';
import {
  createBookmarkResetAction,
  deleteBookmarkResetAction,
} from '../../../common/components/Bookmark/logic';
import AddInvestigator from '../../components/AddInvestigator';
import SearchResultFilters from './searchResultFilters';
import './searchbar.scss';
import AddNetwork from '../../components/AddNetwork';
import AddCentersManuallyAddNew from '../../components/AddCenterManuallyAddNew';
import { addSiteResetAction } from '../../components/AddCentersManually/logic';
import { addParentResetAction } from '../../components/AddCenterManuallyAddNew/logic';
import AddEditParentInstitute from '../../components/AddEditParentInstitute';

const SearchResults = ({ modalProps }) => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { Option } = Select;
  const [query, setQuery] = useState('');
  const [from, setFrom] = useState(1);
  const [sizeVal, setSizeVal] = useState(10);
  const [tabsData, setTabsData] = useState([]);
  const [currentTab, setCurrentTab] = useState('');
  const [showMap, setShowMap] = useState(false);
  const countsData = useSelector((state) => state.countsData);
  const searchFieldsFilterData = useSelector((state) => state.searchFieldsFilterData);
  const datasetListData = useSelector((state) => state.datasetListData);
  const createBookmark = useSelector((store) => store.createBookmark);
  const deleteBookmark = useSelector((store) => store.deleteBookmark);
  const fieldFilters = useSelector((store) => store.searchFieldsFilterData);
  const mapCoordinates = useSelector((store) => store.mapCoordinates);
  const [fieldFiltersData, setFieldFiltersData] = useState([]);
  const [valueSelectedForFilter, setValueSelectedForFilter] = useState([]);
  const [fieldFilterDropdown, setFieldFilterDropdown] = useState(false);
  const [partialMatch, setPartialMatch] = useState(false);
  const [searchPi, setSearchPi] = useState(false);
  const [searchCenter, setSearchCenter] = useState(false);
  const [flagName, setFlag] = useState(false);
  const [analysisId, setAnalysisId] = useState(false);
  const [addNewNetworkModal, setAddNewNetworkModal] = useState(false);
  const [switchChange, setSwitchChange] = useState(false);
  const [selectSortBy, setSelectSortBy] = useState('');
  const [sortFilter, setSortFilter] = useState('asc');
  const [isSortByPopoverVisible, setIsSortByPopoverVisible] = useState(false);
  const [appliedFilters, setAppliedFilters] = useState({
    alliance: undefined,
    quality_rating: undefined,
    tier: undefined,
  });
  const { Panel } = Collapse;
  const advanceFilterData = JSON.parse(localStorage.getItem('advance_search'))
  const [mergeType, setMergeType] = useState('')
  const [centerForMerging, setCenterForMerging] = useState({
    isCenterSelected: false,
    centerOneId: '',
    centerTwoId: [],
    centerOneData: {},
    centerTwoData: [],
  })

  const [piForMerging, setPiForMerging] = useState({
    isPiSelected: false,
    piOneId: '',
    piTwoId: [],
    piOneData: {},
    piTwoData: [],
  })

  useEffect(() => {
    if (fieldFilters.flag) {
      setFieldFiltersData(fieldFilters.data);
      const temparray = [];
      fieldFilters.data.map((item) => temparray.push(item.key));
      setValueSelectedForFilter(temparray);
    }
  }, [JSON.stringify(fieldFilters)]);

  useEffect(() => {
    if (parse(location.search).currentTab || parse(location.search).query) {
      if (parse(location.search).currentTab === 'parent_centers') {
        dispatch(
          getSearchFieldsFilterAction({
            dataset: 'centers',
            type: 'search_fields',
          }),
        );
      } else if (parse(location.search).currentTab || parse(location.search).searchfield) {
        dispatch(
          getSearchFieldsFilterAction({
            dataset: parse(location.search).currentTab || parse(location.search).searchfield,
            type: 'search_fields',
          }),
        );
      }
    }
    if (parse(location.search).currentTab) {
      dispatch(
        getDatasetListDataAction({
          query,
          dataset:
            parse(location.search).currentTab === 'parent_centers' ? 'centers' : parse(location.search).currentTab,
          from,
          size: sizeVal,
          filters: parse(location.search).currentTab === 'parent_centers' ? JSON.stringify({ ...advanceFilterData, parent_flag: true }) : JSON.stringify({ ...advanceFilterData, parent_flag: false }),
          order_by: selectSortBy ? JSON.stringify({ [selectSortBy]: sortFilter }) : undefined,
          partial_match: partialMatch,
        }),
      )
    }
  }, [parse(location.search).currentTab, parse(location.search).query])

  useEffect(() => {
    if (createBookmark.flag) {
      dispatch(
        getDatasetListDataAction({
          query,
          dataset: parse(location.search).currentTab === 'parent_centers' ? 'centers' : parse(location.search).currentTab || countsData.data.data[0].value,
          from,
          size: sizeVal,
          order_by: selectSortBy ? JSON.stringify({ [selectSortBy]: sortFilter }) : undefined,
          filters: parse(location.search).currentTab === 'parent_centers' ? JSON.stringify({ ...appliedFilters, parent_flag: true, ...advanceFilterData }) : JSON.stringify({ ...appliedFilters, ...advanceFilterData, parent_flag: false }),
          partial_match: partialMatch,
          search_fields:
            valueSelectedForFilter.length !== 0
              ? valueSelectedForFilter.join(',')
              : undefined,
        }),
      );
      dispatch(createBookmarkResetAction());
    }
    if (deleteBookmark.flag) {
      dispatch(
        getDatasetListDataAction({
          query,
          dataset: parse(location.search).currentTab === 'parent_centers' ? 'centers' : parse(location.search).currentTab || countsData.data.data[0].value,
          from,
          size: sizeVal,
          order_by: selectSortBy ? JSON.stringify({ [selectSortBy]: sortFilter }) : undefined,
          filters: parse(location.search).currentTab === 'parent_centers' ? JSON.stringify({ ...appliedFilters, ...advanceFilterData, parent_flag: true }) : JSON.stringify({ ...appliedFilters, ...advanceFilterData, parent_flag: false }),
          partial_match: partialMatch,
          search_fields:
            valueSelectedForFilter.length !== 0
              ? valueSelectedForFilter.join(',')
              : undefined,
        }),
      );
      dispatch(deleteBookmarkResetAction());
    }
  }, [JSON.stringify(createBookmark), JSON.stringify(deleteBookmark)]);

  useEffect(() => {
    if (modalProps) {
      setQuery(modalProps.params.query);
      let params
      if (modalProps.params.query) {
        params = {
          query: modalProps.params.query,
          partial_match: partialMatch,
          filters: localStorage.getItem('advance_search'),
        }
      } else {
        params = {
          partial_match: partialMatch,
          filters: localStorage.getItem('advance_search'),
        }
      }
      dispatch(
        getCountsDataAction(params),
      );
    } else {
      setAppliedFilters({
        alliance: undefined,
        quality_rating: undefined,
        tier: undefined,
      });
      setSelectSortBy('');
      setSortFilter('asc');
      setShowMap(false);
      setQuery(parse(location.search).query);
      let params
      if (parse(location.search).query) {
        params = {
          query: parse(location.search).query,
          partial_match: partialMatch,
          filters: localStorage.getItem('advance_search'),
        }
      } else {
        params = {
          partial_match: partialMatch,
          filters: localStorage.getItem('advance_search'),
        }
      }
      dispatch(
        getCountsDataAction(params),
      );
    }
  }, [parse(location.search).query, localStorage.getItem('advance_search')]);

  useEffect(() => {
    setCurrentTab(parse(location.search).currentTab);
  }, [parse(location.search).currentTab])

  useEffect(() => {
    if (countsData.flag) {
      if (parse(location.search).currentTab) {
        setCurrentTab(parse(location.search).currentTab);
      } else {
        setCurrentTab(countsData.data.data[0].value);
      }
      if (!isViewOnlyAccess()) {
        setTabsData(countsData.data.data);
      } else {
        const data = countsData.data.data.filter((item) => item.name !== 'Projects')
        setTabsData(data)
      }

      const params = { query, dataset: parse(location.search).currentTab || countsData.data.data[0].value, partial_match: partialMatch }
      if (searchFieldsFilterData.flag && query) {
        setAnalytics('searchResults', 'data', params)
      }
      dispatch(
        getDatasetListDataAction({
          query,
          dataset:
            parse(location.search).currentTab === 'parent_centers' ? 'centers' : parse(location.search).currentTab || countsData.data.data[0].value,
          from,
          size: sizeVal,
          filters: parse(location.search).currentTab === 'parent_centers' ? JSON.stringify({ ...advanceFilterData, parent_flag: true }) : JSON.stringify({ ...advanceFilterData, parent_flag: false }),
          order_by: selectSortBy ? JSON.stringify({ [selectSortBy]: sortFilter }) : undefined,
          partial_match: partialMatch,
        }),
      );
    }
  }, [JSON.stringify(countsData)]);

  useEffect(() => {
    if (
      parse(location.search).searchfield === 'all' || parse(location.search).searchfield === ''
    ) {
      const params = {
        ...parse(location.search),
      };
      history.replace(`${location.pathname}?${stringify(params)}`);
      if (!modalProps) {
        dispatch(
          getSearchFieldsFilterAction({
            dataset: parse(location.search).currentTab === 'parent_centers' ? 'centers' : parse(location.search).currentTab || 'investigators',
            type: 'search_fields',
          }),
        )
      }
    } else {
      const params = {
        ...parse(location.search),
      };
      history.replace(`${location.pathname}?${stringify(params)}`);
      if (!modalProps) {
        dispatch(
          getSearchFieldsFilterAction({
            dataset: parse(location.search).currentTab === 'parent_centers' ? 'centers' : parse(location.search).currentTab || parse(location.search).searchfield,
            type: 'search_fields',
          }),
        );
      }
    }
  }, []);
  useEffect(() => {
    const params = {
      ...parse(location.search),
    };
    history.replace(`${location.pathname}?${stringify(params)}`);
  }, [parse(location.search).searchfield]);

  const renderTabsInfo = () => {
    return modalProps ? (
      <DocumentList
        activeDataset={currentTab}
        query={query}
        IsModalProps={!!modalProps}
        analysisId={modalProps.analysisId}
        modalStatus={modalProps.setsearchPi}
      />
    ) : (
      <DocumentList
        activeDataset={currentTab}
        query={query}
        appliedFilters={appliedFilters}
        selectSortBy={selectSortBy}
        sortFilter={sortFilter}
        partialMatch={partialMatch}
        searchFields={valueSelectedForFilter}
        mergeType={mergeType}
        setMergeType={setMergeType}
        centerForMerging={centerForMerging}
        setCenterForMerging={setCenterForMerging}
        piForMerging={piForMerging}
        setPiForMerging={setPiForMerging}
      />
    );
  };

  const onTabChange = (tab) => {
    if (tab.value !== currentTab) {
      setSelectSortBy('')
      dispatch(getMapCoordinatesResetAction());
      setValueSelectedForFilter([]);
      setValueSelectedForFilter([]);
      setCenterForMerging({
        isCenterSelected: false,
        centerOneId: '',
        centerTwoId: [],
        centerOneData: {},
        centerTwoData: [],
      })
      setPiForMerging({
        isPiSelected: false,
        piOneId: '',
        piTwoId: [],
        piOneData: {},
        piTwoData: [],
      })
      if (tab.value !== currentTab && currentTab !== 'all') {
        const temp = {
          alliance: undefined,
          quality_rating: undefined,
          tier: undefined,
        };
        const params = {
          ...parse(location.search),
          currentTab: tab.value,
        };

        history.replace(`${location.pathname}?${stringify(params)}`);
        setShowMap(false);
        setAppliedFilters(temp);
        setCurrentTab(tab.value);
        setAnalytics('searchResults', 'data', JSON.stringify({ query, dataset: tab.value, partial_match: partialMatch }))
        dispatch(
          getDatasetListDataAction({
            query,
            dataset: tab.value === 'parent_centers' ? 'centers' : tab.value,
            from,
            size: sizeVal,
            filters: tab.value === 'parent_centers' ? JSON.stringify({ ...temp, parent_flag: true }) : JSON.stringify({ ...temp, parent_flag: false }),
            partial_match: partialMatch,
          }),
        );

        if (parse(location.search).searchfield !== 'all') {
          setSizeVal(50);
          if (!modalProps) {
            dispatch(
              getSearchFieldsFilterAction({
                dataset: tab.value === 'parent_centers' ? 'centers' : tab.value,
                type: 'search_fields',
              }),
            );
          }
        }
      }
    }
  };

  const OnAddInvestigatorClick = () => {
    setSearchPi(true);
    const analysisId1 = parse(location.search)?.project_id;
    if (analysisId1) {
      setFlag('savedAnalysis');
      setAnalysisId(analysisId1);
    } else {
      setFlag('fromMainPage');
      setSearchPi(true);
    }
  };

  const handlePiCancel = () => {
    setSearchPi(false);
  };

  const handleCenterCancel = () => {
    setSearchCenter(false);
  };

  const OnAddCenterClick = () => {
    setSearchCenter(true);
    dispatch(addSiteResetAction())
    dispatch(addParentResetAction())
  };

  const applyFilters = (filtersObj) => {
    if (localStorage.getItem('advance_search')) {
      const advFilter = JSON.parse(localStorage.getItem('advance_search'))
      const { advanced_filters } = advFilter
      setAppliedFilters({ ...appliedFilters, ...filtersObj });
      setFrom(1);
      dispatch(
        getDatasetListDataAction({
          query,
          dataset:
            parse(location.search).currentTab === 'parent_centers' ? 'centers' : parse(location.search).currentTab || countsData.data.data[0].value,
          from: 1,
          size: sizeVal,
          filters: parse(location.search).currentTab === 'parent_centers' ? JSON.stringify({
            ...appliedFilters, ...filtersObj, advanced_filters, parent_flag: true,
          }) : JSON.stringify({
            ...appliedFilters, ...filtersObj, advanced_filters, parent_flag: false,
          }),
          partial_match: partialMatch,
          order_by: selectSortBy ? JSON.stringify({ [selectSortBy]: sortFilter }) : undefined,
          search_fields:
            valueSelectedForFilter.length !== 0
              ? valueSelectedForFilter.join(',')
              : undefined,
        }),
      );
    } else {
      setAppliedFilters({ ...appliedFilters, ...filtersObj });
      setFrom(1);
      dispatch(
        getDatasetListDataAction({
          query,
          dataset:
            parse(location.search).currentTab === 'parent_centers' ? 'centers' : parse(location.search).currentTab || countsData.data.data[0].value,
          from: 1,
          size: sizeVal,
          filters: parse(location.search).currentTab === 'parent_centers' ? JSON.stringify({ ...appliedFilters, ...filtersObj, parent_flag: true }) : JSON.stringify({ ...appliedFilters, ...filtersObj, parent_flag: false }),
          partial_match: partialMatch,
          order_by: selectSortBy ? JSON.stringify({ [selectSortBy]: sortFilter }) : undefined,
          search_fields:
            valueSelectedForFilter.length !== 0
              ? valueSelectedForFilter.join(',')
              : undefined,
        }),
      );
    }
    const params = {
      query, dataset: parse(location.search).currentTab || countsData.data.data[0].value, size: sizeVal, filters: { ...appliedFilters, ...filtersObj }, partial_match: partialMatch, search_fields: valueSelectedForFilter.length !== 0 ? valueSelectedForFilter.join(',') : undefined,
    }
    if (datasetListData.flag && query) {
      setAnalytics('searchFilter', 'data', params)
    }
  };

  const resetFilters = () => {
    const temp = {
      alliance: undefined,
      quality_rating: undefined,
      tier: undefined,
    };
    setAppliedFilters(temp);
    setFrom(1);

    dispatch(
      getDatasetListDataAction({
        query,
        dataset:
          parse(location.search).currentTab === 'parent_centers' ? 'centers' : parse(location.search).currentTab || countsData.data.data[0].value,
        from: 1,
        size: sizeVal,
        filters: parse(location.search).currentTab === 'parent_centers' ? JSON.stringify({ temp, parent_flag: true }) : JSON.stringify({ temp, parent_flag: false }),
        partial_match: partialMatch,
        search_fields:
          valueSelectedForFilter.length !== 0
            ? valueSelectedForFilter.join(',')
            : undefined,
      }),
    );
  };

  const onSwitchChange = (checked) => {
    setSwitchChange(checked);
  };

  const handleCreateProject = () => {
    history.push('/projects/createproject');
  };

  const exportNetworksClicked = () => {
    const filename = `${currentTab}_list_records.xlsx`
    const p = {};
    p[selectSortBy] = sortFilter;
    const params = {
      query,
      order_by: JSON.stringify(p),
      dataset:
        parse(location.search).currentTab === 'parent_centers' ? 'centers' : parse(location.search).currentTab || countsData.data.data[0].value,
      from: 1,
      size: sizeVal,
      filters: parse(location.search).currentTab === 'parent_centers' ? JSON.stringify({ ...appliedFilters, ...advanceFilterData, parent_flag: true }) : JSON.stringify({ ...appliedFilters, ...advanceFilterData, parent_flag: false }),
      partial_match: partialMatch,
    }
    Axios({
      url: `${process.env.apiUrl}v0/export/searchResults?${stringify(params)}`,
      method: 'GET',
      responseType: 'blob', // important
      headers: {
        Authorization: getCookie('accessToken'),
        'Content-type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'Access-Control-Allow-Origin': `${process.env.applicationUrl}`,
      },
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', filename)
        document.body.appendChild(link)
        link.click();
        return null;
      })
      .catch((e) => {
        return e;
      })
  }

  const sortBy = {
    investigators: [
      {
        title: 'Investigator First Name',
        value: 'first_name',
        sortby: 'asc',
      },
      {
        title: 'Investigator Last Name',
        value: 'last_name',
        sortby: 'asc',
      },
      {
        title: '# Industry Studies',
        value: 'industry_studies',
        sortby: 'desc',
      },
      {
        title: 'PXL # Studies',
        value: 'parexel_studies',
        sortby: 'desc',
      },
      {
        title: 'Enrolled Subjects',
        value: 'enrollment_subjects',
        sortby: 'asc',
      },
      {
        title: 'Tier',
        value: 'tier',
        sortby: 'asc',
      },
      {
        title: 'KOL Score',
        value: 'cls_kol_score',
        sortby: 'asc',
      },
      {
        title: 'Reliablity',
        value: 'reliability',
        sortby: 'asc',
      },
      {
        title: 'Quality',
        value: 'quality_rating',
        sortby: 'asc',
      },
      {
        title: 'Last Modified Date',
        value: 'last_modified_date',
        sortby: 'asc',
      },
      {
        title: 'Emerging KOL Class',
        value: 'emerging_kol_score',
        sortby: 'asc',
      },
    ],

    centers: [
      {
        title: 'Center Name',
        value: 'name',
        sortby: 'asc',
      },
      {
        title: '# Industry Studies',
        value: 'industry_studies',
        sortby: 'desc',
      },
      {
        title: 'PXL # Studies',
        value: 'parexel_studies',
        sortby: 'desc',
      },
      {
        title: 'Tier',
        value: 'tier',
        sortby: 'asc',
      },
      {
        title: 'Reliablity',
        value: 'reliability',
        sortby: 'asc',
      },
      {
        title: 'Quality',
        value: 'quality',
        sortby: 'asc',
      },
      {
        title: 'Enrolled Subjects',
        value: 'enrollment_subjects',
        sortby: 'asc',
      },
      {
        title: 'Total # PIs',
        value: 'total_number_of_popis',
        sortby: 'asc',
      },
      {
        title: 'Last Modified Date',
        value: 'last_modified_date',
        sortby: 'asc',
      },
    ],
    parent_centers: [
      {
        title: 'Center Name',
        value: 'name',
        sortby: 'asc',
      },
      {
        title: '# Industry Studies',
        value: 'industry_studies',
        sortby: 'desc',
      },
      {
        title: 'PXL # Studies',
        value: 'parexel_studies',
        sortby: 'desc',
      },
      {
        title: 'Reliablity',
        value: 'reliability',
        sortby: 'asc',
      },
      {
        title: 'Quality',
        value: 'quality',
        sortby: 'asc',
      },
      {
        title: 'Enrolled Subjects',
        value: 'enrollment_subjects',
        sortby: 'asc',
      },
      {
        title: 'Total # PIs',
        value: 'total_number_of_popis',
        sortby: 'asc',
      },
      {
        title: 'Last Modified Date',
        value: 'last_modified_date',
        sortby: 'asc',
      },
    ],
  };

  const handleSortChange = (value) => {
    setSelectSortBy(value);
  };

  const onSortApply = () => {
    const p = {};
    p[selectSortBy] = sortFilter;
    setIsSortByPopoverVisible(false);
    if (localStorage.getItem('advance_search')) {
      const adv_ser = JSON.parse(localStorage.getItem('advance_search'))
      dispatch(
        getDatasetListDataAction({
          query,
          order_by: JSON.stringify(p),
          dataset:
            parse(location.search).currentTab === 'parent_centers' ? 'centers' : parse(location.search).currentTab || countsData.data.data[0].value,
          from: 1,
          size: sizeVal,
          filters: parse(location.search).currentTab === 'parent_centers' ? JSON.stringify({ ...adv_ser, parent_flag: true }) : JSON.stringify({ ...adv_ser, parent_flag: false }),
          partial_match: partialMatch,
        }),
      );
    } else {
      dispatch(
        getDatasetListDataAction({
          query,
          order_by: JSON.stringify(p),
          dataset:
            parse(location.search).currentTab === 'parent_centers' ? 'centers' : parse(location.search).currentTab || countsData.data.data[0].value,
          from: 1,
          size: sizeVal,
          filters: parse(location.search).currentTab === 'parent_centers' ? JSON.stringify({ ...appliedFilters, parent_flag: true }) : JSON.stringify({ ...appliedFilters, parent_flag: false }),
          partial_match: partialMatch,
          search_fields:
            valueSelectedForFilter.length !== 0
              ? valueSelectedForFilter.join(',')
              : undefined,
        }),
      );
    }
    const params = {
      query, dataset: parse(location.search).currentTab || countsData.data.data[0].value, search_fields: valueSelectedForFilter.join(','), partial_match: partialMatch, order_by: JSON.stringify(p),
    }
    if (datasetListData.flag && query) {
      setAnalytics('searchSort', 'data', params)
    }
  };

  const searchToggleList = () => {
    setShowMap(false)
    const params = 'List'
    setAnalytics('searchToggleList', 'data', params)
  }

  const onDisplayMapClick = () => {
    dispatch(
      getMapCoordinatesAction({
        query,
        dataset:
          parse(location.search).currentTab === 'parent_centers' ? 'centers' : parse(location.search).currentTab || countsData.data.data[0].value,
        filters: parse(location.search).currentTab === 'parent_centers' ? JSON.stringify({ ...appliedFilters, parent_flag: true }) : JSON.stringify({ ...appliedFilters, parent_flag: false }),
        partial_match: partialMatch,
        search_fields:
          valueSelectedForFilter.length !== 0
            ? valueSelectedForFilter.join(',')
            : undefined,
      }),
    );
    setShowMap(true);
    const params = 'Map'
    setAnalytics('searchToggleMap', 'data', params)
  };

  const contentt = (
    <>
      {currentTab && (currentTab === 'centers' || currentTab === 'parent_centers' || currentTab === 'investigators')
        ? (
          <>
            <div className="sortby-wrap">
              <div className="sortby-type-content">
                <Select placeholder="Select options" value={selectSortBy} onChange={handleSortChange}>
                  {sortBy[currentTab].map((v) => {
                    return <Option value={v.value}>{v.title}</Option>;
                  })}
                </Select>
              </div>
              <div className="sortby-type-content">
                <Select
                  style={{
                    width: 120,
                  }}
                  defaultValue="asc"
                  value={sortFilter}
                  onChange={(value) => {
                    setSortFilter(value);
                  }}
                >
                  <Option value="asc">Ascending</Option>
                  <Option value="desc">Descending</Option>
                </Select>
              </div>
            </div>
            <div className="sortby-btns-wrap">
              <Button className="apply-btn" type="primary" onClick={onSortApply}>
                Apply
              </Button>
            </div>
          </>
        ) : null}
    </>
  );

  const handleToggleChange = (e) => {
    setPartialMatch(e);
    dispatch(
      getCountsDataAction({
        query: parse(location.search).query || '',
        partial_match: e,
      }),
    );
    dispatch(
      getDatasetListDataAction({
        query,
        dataset:
          parse(location.search).currentTab === 'parent_centers' ? 'centers' : parse(location.search).currentTab || countsData.data.data[0].value,
        from,
        size: sizeVal,
        filters: parse(location.search).currentTab === 'parent_centers' ? JSON.stringify({ ...appliedFilters, parent_flag: true }) : JSON.stringify({ ...appliedFilters, parent_flag: false }),
        partial_match: e,
        order_by: selectSortBy ? JSON.stringify({ [selectSortBy]: sortFilter }) : undefined,
        search_fields:
          valueSelectedForFilter.length !== 0
            ? valueSelectedForFilter.join(',')
            : undefined,
      }),
    );

    const params = {
      query,
      dataset: parse(location.search).currentTab || countsData.data.data[0].value,
      partial_match: e,
      filters: JSON.stringify(appliedFilters),
      size: sizeVal,
      search_fields:
        valueSelectedForFilter.length !== 0
          ? valueSelectedForFilter.join(',')
          : undefined,
    }
    if (searchFieldsFilterData.flag && query) {
      setAnalytics('searchResultsPartialSearch', 'data', params)
    }
  };

  const handleFieldFilterChange = (e) => {
    setValueSelectedForFilter(e);
  };

  const handleApplyFieldFilterClicked = () => {
    dispatch(
      getDatasetListDataAction({
        query,
        dataset:
          parse(location.search).currentTab === 'parent_centers' ? 'centers' : parse(location.search).currentTab || countsData.data.data[0].value,
        from,
        size: sizeVal,
        filters: parse(location.search).currentTab === 'parent_centers' ? JSON.stringify({ ...appliedFilters, parent_flag: true }) : JSON.stringify({ ...appliedFilters, parent_flag: false }),
        partial_match: partialMatch,
        search_fields: valueSelectedForFilter.join(','),
      }),
    );

    setFieldFilterDropdown(false);
    const params = { dataset: parse(location.search).currentTab || countsData.data.data[0].value, search_fields: valueSelectedForFilter.join(','), partial_match: partialMatch }
    if (datasetListData.flag && query) {
      setAnalytics('searchFields', 'data', params)
    }
  };

  return (
    <div className="whole-search-page">
      <div className="middle-part">
        {!modalProps && (
          <div className="partial-head">
            <div className="partial-head-title">Partial Search :</div>
            <Switch
              checkedChildren="Yes"
              unCheckedChildren="No"
              onChange={handleToggleChange}
            />
          </div>
        )}
        {
          console.log('tabsData', tabsData)
        }
        <div className="upper-divof-search-Tabs">
          <div className="sub-header-search sub-header-search-page">
            <div className="searbar-tabs">
              <Loader loading={countsData.loading} error={countsData.error}>
                {!modalProps && tabsData.map((itm, index) => (
                  <div
                    className={`tagsbutton ${currentTab === itm.value ? 'active' : ''
                    } ${!itm.isData ? 'disabled' : ''}`}
                    role="presentation"
                    id={itm.name}
                    onClick={() => onTabChange(itm)}
                    key={index}
                  >
                    <div className="key-name">
                      {itm.name === 'Parent Centers' ? 'Parent Institutions' : itm.name}
                      &nbsp;
                    </div>
                    <div className="key-size">({itm.count})</div>
                  </div>
                ))}
              </Loader>
            </div>
            {
              (parse(location.search).currentTab === 'networks' || parse(location.search).currentTab === 'centers' || parse(location.search).currentTab === 'investigators')
              && (
                <div className="export-to-csv-btn export-to-csv-network-btn">
                  <Button className="export-to-csv-btn-icon export-networks-button" title="Export" onClick={() => exportNetworksClicked()} disabled={datasetListData.loading} />
                </div>
              )
            }
            {(!modalProps && localStorage.getItem('advance_search') === null) && (
              <div className="filter-dropdown filter-dropdown-sub-head">
                <div className="filter-dropdown-title">Search by Fields :</div>
                <div className="filter-dropdown-select">
                  <Select
                    mode="multiple"
                    loading={fieldFiltersData.loading}
                    allowClear
                    open={fieldFilterDropdown}
                    onDropdownVisibleChange={(visible) => setFieldFilterDropdown(visible)}
                    style={{ width: '100px' }}
                    value={valueSelectedForFilter}
                    onChange={handleFieldFilterChange}
                    dropdownRender={(menu) => (
                      <>
                        {menu}
                        <div className="searchbyfields-apply-btn">
                          <Button
                            disabled={valueSelectedForFilter.length === 0}
                            onClick={handleApplyFieldFilterClicked}
                          >
                            Apply
                          </Button>
                        </div>
                      </>
                    )}
                  >
                    {fieldFiltersData?.map((value) => {
                      return <Option value={value.key}>{value.category}</Option>;
                    })}
                  </Select>
                </div>
              </div>
            )}
            <div className="right-sidebar">
              {(currentTab === 'centers' || currentTab === 'parent_centers' || currentTab === 'investigators') && !modalProps
                ? (
                  <div className="btn-sidebar">
                    <Popover
                      overlayClassName="sortby-runanalysis-popover"
                      placement="left"
                      title="Sort by:"
                      content={contentt}
                      trigger="click"
                      visible={isSortByPopoverVisible}
                      onVisibleChange={(visibled) => {
                        setIsSortByPopoverVisible(visibled);
                      }}
                    >
                      <div
                        className="sortby-icon"
                        type="primary"
                        role="presentation"
                      >
                        <Tooltip title="Sort">
                          <div className="sort-icon-img" />
                        </Tooltip>
                      </div>
                    </Popover>
                  </div>
                ) : null}
              {currentTab === 'centers' || currentTab === 'investigators' || currentTab === 'parent_centers'
                ? (
                  <div className="toggle-switch-wrap">
                    <Tooltip title="List View">
                      <div
                        className={`toggle-switch-itm ${!showMap ? 'active' : ''}`}
                        role="presentation"
                        onClick={searchToggleList}

                      >
                        List
                      </div>
                    </Tooltip>
                    <Tooltip title="Map View">
                      <div
                        className={`toggle-switch-itm ${showMap ? 'active' : ''}`}
                        role="presentation"
                        onClick={() => onDisplayMapClick()}
                      >
                        Map
                      </div>
                    </Tooltip>
                  </div>
                ) : null}
            </div>
          </div>
        </div>
        {currentTab !== 'networks' && currentTab !== 'projects' && !modalProps && (
          <Tooltip title="Filters">
            <Switch
              onChange={onSwitchChange}
              className="switch-sidebar-content"
            />
          </Tooltip>
        )}
        <div className="searchbar-data">
          {switchChange ? (
            <div className="filter-wrap">
              {currentTab !== 'networks' && currentTab !== 'projects' && !modalProps
                ? (
                  <Collapse
                    bordered={false}
                    defaultActiveKey={['1']}
                    expandIcon={({ isActive }) => (
                      <CaretRightOutlined rotate={isActive ? 90 : 0} />
                    )}
                    className="site-collapse-custom-collapse"
                  >
                    <Panel
                      header="Filters"
                      key="1"
                      className="site-collapse-custom-panel sidebar-menu center-map-sidebar sidebar-filter-i-c"
                    >
                      <div className="filter-content">
                        {currentTab ? (
                          <SearchResultFilters
                            dataset={currentTab}
                            query={query}
                            appliedFilters={appliedFilters}
                            onApplyFilters={applyFilters}
                            onReset={resetFilters}
                          />
                        ) : null}
                      </div>
                    </Panel>
                  </Collapse>
                ) : null}
            </div>
          ) : null}
          <div className="search-result">
            <Loader
              loading={countsData.loading || datasetListData.loading}
              error={datasetListData.error}
            >
              {(currentTab === 'centers' || currentTab === 'investigators' || currentTab === 'parent_centers') && showMap
                ? (
                  <>
                    {currentTab === 'centers' || currentTab === 'parent_centers' ? (
                      <Loader
                        loading={mapCoordinates.loading}
                        error={mapCoordinates.error}
                      >
                        <WorldMap data={mapCoordinates.data.data} query={query} />
                      </Loader>
                    ) : (
                      <Loader
                        loading={mapCoordinates.loading}
                        error={mapCoordinates.error}
                      >
                        <PIMap data={mapCoordinates.data.data} query={query} />
                      </Loader>
                    )}
                  </>
                ) : (
                  renderTabsInfo()
                )}
            </Loader>
          </div>
        </div>
        {currentTab === 'projects' && !isViewOnlyAccess()
          ? (
            <div className="plus-add-btn">
              <button onClick={handleCreateProject} type="button" className="plus-btn">
                <span className="title" style={{ display: 'none' }}>
                  Create Project
                </span>
                <span className="title-plus">+</span>
              </button>
            </div>
          )
          : null}
        {(currentTab === 'investigators' && !isViewOnlyAccess()) && (
          <div className="plus-add-btn">
            <button
              onClick={OnAddInvestigatorClick}
              type="button"
              className="plus-btn"
            >
              <span className="title" style={{ display: 'none' }}>
                Add Investigator&nbsp;
              </span>
              <span className="title-plus"> +</span>
            </button>
            {searchPi && (
              <Modal
                title="Add New Investigator"
                open={searchPi}
                onCancel={handlePiCancel}
                className="modal-wrapper add-investigator-modal"
                maskClosable={false}
                destroyOnClose
                footer={null}
              >
                <div className="add-investigator-section add-investigator-section-lvl-up">
                  <AddInvestigator
                    flag={flagName}
                    setModal={setSearchPi}
                    analysisId={analysisId}
                  />
                </div>
              </Modal>
            )}
          </div>
        )}

        {(currentTab === 'centers' && !isViewOnlyAccess()) && (
          <div className="plus-add-btn">
            <button
              onClick={OnAddCenterClick}
              type="button"
              className="plus-btn"
            >
              <span className="title" style={{ display: 'none' }}>
                Add Center&nbsp;
              </span>
              <span className="title-plus"> +</span>
            </button>
            {searchCenter && (
              <Modal
                title="Add new Center"
                open={searchCenter}
                onCancel={handleCenterCancel}
                className="modal-wrapper add-investigator-modal"
                maskClosable={false}
                destroyOnClose
                footer={null}
              >
                <div className="add-investigator-section add-investigator-section-lvl-up">
                  <AddCentersManuallyAddNew
                    flag="fromMainCenter"
                    setEditModal={setSearchCenter}
                  />
                </div>
              </Modal>
            )}
          </div>
        )}
        {(currentTab === 'parent_centers') && hasPermissionForAction('add_parent') && !isViewOnlyAccess() && (
          <div className="plus-add-btn">
            <button
              onClick={OnAddCenterClick}
              type="button"
              className="plus-btn"
            >
              <span className="title" style={{ display: 'none' }}>
                Add Parent Institution&nbsp;
              </span>
              <span className="title-plus"> +</span>
            </button>
            {searchCenter && (
              <Modal
                title={currentTab === 'parent_centers' ? 'Add New Parent Institution' : 'Add new Center'}
                open={searchCenter}
                onCancel={handleCenterCancel}
                className="modal-wrapper add-investigator-modal"
                maskClosable={false}
                destroyOnClose
                footer={null}
              >
                <div className="add-investigator-section add-investigator-section-lvl-up">
                  {currentTab === 'parent_centers' ? (
                    <AddEditParentInstitute
                      flag="fromMainCenter"
                      setEditModal={setSearchCenter}
                    />
                  )
                    : (
                      <AddCentersManuallyAddNew
                        flag="fromMainCenter"
                        setEditModal={setSearchCenter}
                      />
                    ) }
                </div>
              </Modal>
            )}
          </div>
        )}
        {currentTab === 'networks' && !isViewOnlyAccess() && (
          <div className="plus-add-btn">
            <button
              type="button"
              onClick={() => setAddNewNetworkModal(true)}
              className="plus-btn"
            >
              <span className="title" style={{ display: 'none' }}>
                Add Network&nbsp;
              </span>
              <span className="title-plus"> +</span>
            </button>
            {addNewNetworkModal && (
              <AddNetwork addNewNetworkModal={addNewNetworkModal} setAddNewNetworkModal={setAddNewNetworkModal} from="searchResult" />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

SearchResults.propTypes = {};

export default SearchResults
